<template>
  <div class="icon-bg">
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.392654 10.7062C0.00212961 10.3157 0.00212941 9.68252 0.392654 9.292L2.99926 6.6854V3.9991C2.99926 3.44682 3.44697 2.9991 3.99926 2.9991H6.68555L8.87793 0.806717C9.26846 0.416192 9.90162 0.416192 10.2921 0.806717L12.4845 2.9991H15.9993C16.5515 2.9991 16.9993 3.44682 16.9993 3.9991V7.51382L18.7774 9.292C19.168 9.68252 19.168 10.3157 18.7774 10.7062L16.9993 12.4844V15.9991C16.9993 16.5514 16.5515 16.9991 15.9993 16.9991H12.4845L10.2921 19.1915C9.90162 19.582 9.26846 19.582 8.87793 19.1915L6.68555 16.9991H3.99926C3.44697 16.9991 2.99926 16.5514 2.99926 15.9991V13.3128L0.392654 10.7062ZM9.99926 12.9991C11.6561 12.9991 12.9993 11.656 12.9993 9.9991C12.9993 8.34225 11.6561 6.9991 9.99926 6.9991C8.3424 6.9991 6.99926 8.34225 6.99926 9.9991C6.99926 11.656 8.3424 12.9991 9.99926 12.9991Z"
        fill="#A7A9C0"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "IconAdmin",
  mixins: [],
  props: {},
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.icon-bg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  flex-shrink: 0;
  transition: 0.1s;
  svg {
    width: 24px;
    height: 24px;
  }
  @media (hover: hover) {
    &:hover {
      background: #ebf2fa;

      svg {
        path {
          fill: #4f52f4;
        }
      }
    }
  }
  &:active {
    background: transparent;
    svg {
      rect {
        fill: #5551f9;
      }
    }
  }
}
</style>
